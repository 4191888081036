@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/HelveticaNeue-Thin.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNeue-Thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/HelveticaNeueLTStd-Lt.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNeueLTStd-Lt.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
    font-family: 'Helvetica Neue';
    src: url('../assets/fonts/HelveticaNeueLT-Medium.woff2') format('woff2'),
        url('../assets/fonts/HelveticaNeueLT-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
} */

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/HelveticaNeueLTStd-Md.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNeueLTStd-Md.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/HelveticaNeueLTStd-Bd.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNeueLTStd-Bd.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('../assets/fonts/founders-grotesk-web-bold.woff2') format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('../assets/fonts/founders-grotesk-web-light.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('../assets/fonts/founders-grotesk-web-medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('../assets/fonts/founders-grotesk-web-regular.woff2') format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('../assets/fonts/founders-grotesk-web-semibold.woff2')
    format('woff2');
  font-weight: 600;
}
